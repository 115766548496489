.main-login {
  background: #2d3546;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  /* height: 100%; */
}

.main-login > img {
  width: 250px;
}

.main-login .form-container {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;
  height: 500px;
}

.main-login .form {
  padding: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}
